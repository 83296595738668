<template>
  <div class="main">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="hidden"
          icon
          small-xs
          text
          v-on="on"
          v-bind="attrs"
          style="float: right"
          @click="generateCSV"
        >
          <Icon name="file_download" style="fill: #4af30e" />
        </v-btn>
      </template>
      <span>Download CSV</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="hidden"
          v-on="on"
          v-bind="attrs"
          small-xs
          text
          icon
          style="float: right"
          @click="openUploadCSVDialog"
        >
          <Icon name="file_upload" style="fill: #9ec0e8" />
        </v-btn>
      </template>
      <span>Uplaod CSV</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          small-xs
          text
          icon
          color="primary"
          style="float: right"
          @click="showCommentBox = true"
        >
          <v-icon> mdi-note-text</v-icon>
        </v-btn>
      </template>
      <span>
        {{
          requestorType != 20
            ? 'Add Comment About Data (what type of data required'
            : 'show data notes'
        }}</span
      >
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          small-xs
          text
          icon
          color="primary"
          style="float: right"
          @click="showDatatable = true"
        >
          <v-icon>mdi-table</v-icon>
        </v-btn>
      </template>
      <span>Show dummy data</span>
    </v-tooltip>
    <v-btn
      class="mt-1 white--text"
      tile
      color="blue-grey"
      style="float: right"
      small
      text
      @click="togglePanel"
      v-if="requestorType === 20 || showEcPanelToOtherThen20"
      >{{
        hidden
          ? showEcPanelToOtherThen20
            ? 'EC data'
            : 'Import EC'
          : 'Dummy Data'
      }}</v-btn
    >
    <v-expansion-panels style="padding: 10px" v-if="showCommentBox">
      <v-expansion-panel style="box-shadow: unset" expand>
        <div style="">
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            style="padding-left: 10px; font-size: 14px"
          >
            <span
              >{{ requestorType != 20 ? 'Add ' : ' ' }}Comment About Data</span
            >
            <span style="float: right" @click.stop="showCommentBox = false"
              ><v-icon>mdi-close</v-icon></span
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-textarea
              solo
              name="input-7-4"
              label="Add comments"
              @change="addComment($event)"
              v-model="creatorComment"
            ></v-textarea>
          </v-expansion-panel-content>
        </div>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      v-if="!hidden || (!hidden && showEcPanelToOtherThen20)"
      style="clear: both"
    >
      <v-autocomplete
        :disabled="showEcPanelToOtherThen20"
        class="ml-3 mr-3 mt-2"
        dense
        rounded
        style="font-size: small"
        :items="allEcs"
        item-text="displayName"
        item-value="name"
        outlined
        label="Select EC"
        v-model="selectedEC"
        @change="getSelectedEcData"
      ></v-autocomplete>
      <div
        v-if="selectedEC"
        class="ec-params-submit d-flex"
        style="width: 100%; margin-bottom: 5px"
      >
        <v-spacer></v-spacer>
        <v-btn outlined small text color="primary" @click="getSelectedEc"
          >Fetch Ec Data</v-btn
        >
      </div>
      <v-expansion-panels style="padding: 10px" v-if="selectedEC">
        <v-expansion-panel style="border: 1px solid #c7bdbd" expand>
          <div style="">
            <v-expansion-panel-header
              expand-icon="mdi-menu-down"
              style="padding-left: 10px; font-size: 14px"
            >
              <span>Ec Params</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(value, key) in ecParams" :key="key">
                <v-text-field
                  class="mx-3"
                  :label="key"
                  :value="value"
                  outlined
                  @change="changeECParam($event, key)"
                ></v-text-field>
              </div>
              <div v-if="!ecParams.length" style="text-align: center">
                No filter required for this ec
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels style="padding: 10px" v-if="selectedEC">
        <v-expansion-panel style="border: 1px solid #c7bdbd" expand>
          <div style="">
            <v-expansion-panel-header
              expand-icon="mdi-menu-down"
              style="padding-left: 10px; font-size: 14px"
            >
              <span>Ec Data</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :headers="headers"
                :items="tableData"
                :items-per-page="5"
                class="elevation-1 mt-5"
              ></v-data-table>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-if="hidden">
      <div
        v-for="(column, index) of dummyDataColumns"
        :key="index"
        style="clear: both; border-radius: 5px; display:flex;    justify-content: center"
        :disabled="requestorType === 20"
      >
      <div>
        <v-text-field
          hide-details
          dense
          label="Enter column header name"
          outlined
          v-model="column.header"
        ></v-text-field>
      </div>
        <div>
        <v-select
          hide-details
          style="margin-top:25px;"
          dense
          class="ml-2 mr-4"
          :items="['Number', 'Text', 'Dynamic']"
          outlined
          v-model="column.type"
          label="Select Column Type"
        ></v-select>
        </div>
        <div class="my-2">
             <v-btn
              small
              dense
              icon
              dark
              style="float: right; margin-top:27px;"
              color="pink"
               v-if="dummyDataColumns.length > 1"
             @click="dummyDataColumns.splice(index, 1)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </div>
        <!-- <v-divider></v-divider> -->
      </div>  <!-- add text tag in vbtn to make the button transparent -->
      <v-btn   
        style="margin-left:200px; margin-bottom:25px; margin-top: 10px;"                
        color="primary"
        text
        small
        @click="
          () => {
            if (this.dummyDataColumns.length < 10) {
              dummyDataColumns.push(getDummyDataColumn())
            } else {
              openSnackbarWithTime({
                txt: 'Maximum 10 rows are allowed',
                time: 3500
              })
            }
          }
        "
        >Add column</v-btn
      >
    </div>
    <v-dialog
      v-model="uploadCSVDialogStatus"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">Upload CSV</h3>
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-file-input
            accept=".csv"
            label="Upload CSV File (Max 10 Rows)"
            v-model="csvFile"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeUploadCSVDialog"
            >Close</v-btn
          >
          <v-btn text color="primary" @click="uploadCSV">Upload CSV</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDatatable"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">
              {{ selectedElement.displayName || selectedElement.name || '' }}
            </h3>
          </div>
          <v-spacer></v-spacer>
          <v-btn text color="pink" @click="showDatatable = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="
              dummyDataColumns.map((el) => ({
                text: el.header,
                value: el.header
              }))
            "
            :items="dummyDataRows"
            :items-per-page="5"
            class="elevation-1 mt-5"
          ></v-data-table>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="errorDialog"
      persistent
      :overlay="false"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title style="color: red"
            >The headers of EC Table and Dummydata Table are not
            matching</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title primary-title>
          <div>
            <div v-if="ecTableHeaders.length && dummyDataColumnHeader.length">
              <p>
                EC Table Columns -
                <span class="table-header">{{ ecTableHeaders }}</span>
              </p>
              <p>
                Dummy Data Columns -
                <span class="table-header">{{ dummyDataColumnHeader }}</span>
              </p>
            </div>
            <div>
              <div v-if="sameHeaders.length">
                <p>
                  Mapping feilds:
                  <span style="color: green">{{ sameHeaders }}</span>
                </p>
              </div>
              <div v-if="diff.length">
                <p>
                  Missing feilds: <span style="color: red">{{ diff }}</span>
                </p>
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeDummyDataDialog">
            Close
          </v-btn>
          <v-btn v-if="ecTableHeaders.length" color="primary" text @click="syncECHeaderWithDummy">
            Sync With Ec Columns/Headers
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import papa from 'papaparse'
let id = 1
import {
  mapState as mapRootState,
  mapMutations as mapRootMutations,
  createNamespacedHelpers as cnh
} from 'vuex'
import { isEqual } from 'lodash'
const { mapGetters, mapMutations, mapState, mapActions } =
  cnh('ElmentBuilderStore')

export default {
  data() {
    return {
      ecKey: '',
      ecValue: '',
      exportparams: {},
      selectedEC: null,
      hidden: this.mortal && (this.mortal.type == 20 || this.mortal.type > 20),
      requestorType: null,
      dummyDataColumns: [this.getDummyDataColumn()],
      csvFile: null,
      dummyDataRows: [],
      uploadCSVDialogStatus: false,
      isSetDataSource: true,
      NofChanges: 0,
      showDatatable: false,
      showCommentBox: false,
      creatorComment: '',
      showEcPanelToOtherThen20: false,
      diff: [],
      ecTableHeaders: [],
      dummyDataColumnHeader: [],
      sameHeaders: [],
      errorDialog: false
    }
  },
  computed: {
    ...mapGetters(['selectedElement']),
    ...mapRootState(['mortal']),
    ...mapState([
      'allEcs',
      'tableData',
      'ecParams',
      'errorTextTableHeader',
      'isError'
    ]),

    headers() {
      let s = new Set()
      this.tableData.forEach((data) => {
        for (let f in data) {
          s.add(f)
        }
      })
      return Array.from(s).map((a) => {
        return {
          text: a,
          value: a
        }
      })
    }
  },
  methods: {
    // ...mapMutations(['getEcs']),
    ...mapMutations([
      'setSelectedElementsProps',
      'setParamstoInitial',
      'setError'
    ]),
    ...mapActions(['getExportData']),
    ecTableAndDummyDataHeaders() {
      this.diff = []
      this.sameHeaders = []
      this.ecTableHeaders = []
      this.dummyDataColumnHeader = []
      this.headers.forEach(({ value }) => {
        this.ecTableHeaders.push(value)
      })
      this.dummyDataColumns.forEach(({ header }) => {
        this.dummyDataColumnHeader.push(header)
      })
    },
    compareTableAndDummyDataHeader() {
      this.diff = this.dummyDataColumnHeader.filter(
        (e) => !this.ecTableHeaders.includes(e)
      )
      this.sameHeaders = this.ecTableHeaders.filter(
        (e) => this.dummyDataColumnHeader.indexOf(e) !== -1
      )
    },
    isDummyDataValid() {
      this.ecTableAndDummyDataHeaders()
      // this.compareTableAndDummyDataHeader();
      // console.log('this.dummyDataColumnHeader :>> ', this.dummyDataColumnHeader);
      console.log('this.ecTableHeaders :>> ', this.ecTableHeaders)
      if (!this.selectedEC) {
        this.setError(false)
        this.errorDialog = false
        return
      }

      let isError = this.dummyDataColumnHeader.reduce((acc, curr) => {
        if (this.ecTableHeaders.includes(curr)) {
          this.sameHeaders.push(curr)
        } else {
          this.diff.push(curr)
          if (!acc) acc = true
        }
        return acc
      }, false)
      // console.log(isError, this.diff, this.sameHeaders);
      this.setError(isError)
      this.errorDialog = isError
    },
    closeDummyDataDialog() {
      // this.setError(false);
      this.errorDialog = false
    },
    getDummyDataColumn() {
      return { header: '', type: '', id: id++ }
    },
    generateCSV() {
      const areDummyDataColumnsValid = this.dummyDataColumns.every(
        (column) => column.header !== '' && column.type !== ''
      )
      if (areDummyDataColumnsValid) {
        const headers = this.dummyDataColumns
          .map((column) => column.header)
          .join(',')
        let rows = ''
        this.dummyDataRows.forEach((e) => {
          Object.keys(e).forEach((k) => {
            rows = rows + e[k] + ','
          })
          rows = rows + '\n'
        })
        const csvContent =
          'data:text/csv;charset=utf-8,' + headers + '\n' + rows
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'Dummy_Data.csv')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    openUploadCSVDialog() {
      this.uploadCSVDialogStatus = true
    },
    closeUploadCSVDialog() {
      this.csvFile = null
      this.uploadCSVDialogStatus = false
    },
    uploadCSV() {
      papa.parse(this.csvFile, {
        // download: true,
        header: true,
        // worker: true,
        // step: validate,
        preview: 10,
        complete: (results) => {
          try {
            console.log(results.data)
            let csvContent = results.data
            const headers = Object.keys(csvContent[0])
            if (headers && csvContent) {
              // console.log(this.dummyDataRows)
              this.dummyDataRows = []
              this.dummyDataColumns = []

              // console.log("****************",header)
              for (let obj in csvContent[0]) {
                let header = obj
                let data = csvContent[0][obj]
                // console.log(">>>>>>>>>>>>>>>>.....",data, !isNaN(data), obj)
                this.dummyDataColumns.push({
                  header: header,
                  type:
                    typeof header == 'string' && isNaN(data)
                      ? 'Text'
                      : typeof header == 'number' || !isNaN(data)
                      ? 'Number'
                      : 'Dynamic'
                })
              }
              for (let row of csvContent) {
                // console.log(el)
                this.dummyDataRows.push(row)
              }
            }
            console.log('dummyDataRows>>>>', this.dummyDataRows)
            this.closeUploadCSVDialog()
          } catch (error) {
            console.log(error)
          }
        }
      })

      // const MAX_ALLOWED_ROWS = 10
      // const fileReader = new FileReader()
      // fileReader.readAsText(this.csvFile)
      // fileReader.onload = (e) => {
      //   const dummyRows = e.target.result.split('\n').splice(1)
      //   console.log(dummyRows)
      //   let headers = e.target.result.split('\n')[0]
      //   console.log("headerssss>>>>>>>>>>", headers)
      //   if (headers) {
      //     this.dummyDataColumns = []
      //     this.dummyDataRows = []
      //   }
      //   console.log("================",this.dummyDataColumns)
      //   headers = headers
      //     .split(',')
      //     .filter((header) => !['', '\r'].includes(header))
      //     .forEach((header) => {
      //       if (this.dummyDataColumns.length < MAX_ALLOWED_ROWS) {
      //         this.dummyDataColumns.push({ header: header.trim() })
      //       }
      //     })
      //     console.log("================",this.dummyDataColumns)
      //     console.log("headers>>>>>>", headers)
      //   dummyRows.forEach((row) => {
      //       // name, age, surname
      //       //   "abc,12, de"
      //     if (row) {
      //       console.log("row>>>>>", row)
      //       let obj = {}
      //       row
      //         .split(',')
      //         .filter((row) => !['', '\r'].includes(row))
      //         .forEach((row, idx) => {
      //           console.log("-----------------",idx)
      //           if (this.dummyDataRows.length < MAX_ALLOWED_ROWS) {
      //             obj[[this.dummyDataColumns[idx].header]] = row
      //               .replace(/\r?\n|\r/g, '')
      //               .trim()
      //               console.log("****************",obj[[this.dummyDataColumns[idx].header]], typeof row)
      //             this.dummyDataColumns[idx].type =
      //               typeof obj[[this.dummyDataColumns[idx].header]] == 'string'
      //                 ? 'Text'
      //                 : !isNaN(obj[[this.dummyDataColumns[idx].header]] )
      //                 ? 'Number'
      //                 : 'Dynamic'
      //           }
      //         })
      //         console.log("objjjjjj", obj)
      //       this.dummyDataRows.push(obj)
      //     }
      //   })
      //   console.log('******* UPLOAD RESULT **********')
      //   console.log(this.dummyDataRows)
      //   console.log('******* UPLOAD RESULT **********')
      //   this.closeUploadCSVDialog()
      // }
    },
    mapHeaderWithConfiguration() {
      this.dummyDataColumns.forEach((el) => {
        let old = this.oldDataHeader.find((f) => el.id == e.id)
        if (old && old.header !== el.header) {
          // "pie_chart"
          if (this.selectedElement.subType == 'pie_chart') {
            if (this.selectedElement.configuration.type !== 'GaugeChart') {
              let configuration = JSON.parse(
                JSON.stringify(this.selectedElement.configuration)
              )
              configuration.series.forEach((ser) => {
                // if()
              })
            }
          }
          if (this.selectedElement.subType == 'column_bar_chart') {
          }
          if (this.selectedElement.subType == 'line_chart') {
          }
        }
      })
    },
    addComment(val) {
      console.log(`[val]: `, val)
      this.setDataSource()
    },
    syncECHeaderWithDummy() {
      this.mapEcColumnsToDummy()
      this.setError(false)
      this.closeDummyDataDialog()
    },
    mapEcColumnsToDummy() {
      if (this.selectedEC) {
        let data = this.tableData[0]
        let removedColumns = JSON.parse(JSON.stringify(this.dummyDataColumns))
        this.dummyDataColumns = this.headers.map((el, idx) => ({
          // 'Number', 'Text', 'Dynamic'
          header: el.value,
          id: idx,
          type:
            isNaN(data[el.value]) && typeof data[el.value] == 'string'
              ? 'Text'
              : !isNaN(data[el.value])
              ? 'Number'
              : 'Dynamic'
        }))
        // let columns = this.dummyDataColumns.map((el) => el.header)
        // removedColumns.forEach((col) => {
        //   if(!columns.includes(col.header)) {

        //   }
        // })
      } else {
        this.dummyDataColumns = [this.getDummyDataColumn()]
      }
      console.log(`[this.dummyDataColumns]: `, this.dummyDataColumns)
    },
    async setDataSource() {
      if (this.NofChanges == 0 && !this.isSetDataSource) {
        this.NofChanges++
        return
      }

      const tempMappingOfHeaderType = {}
      this.dummyDataColumns.forEach((c) => {
        tempMappingOfHeaderType[c.header] =
          c.type && c.type.length ? c.type : 'Text'
      })
      const rows = []
      this.dummyDataRows.forEach((c) => {
        Object.keys(c).forEach((k) => {
          if (
            tempMappingOfHeaderType[k] &&
            tempMappingOfHeaderType[k] === 'Number'
          ) {
            c[k] = Number(c[k])
          } else {
            c[k] = c[k].toString()
          }
        })
        rows.push(c)
      })
      this.dummyDataRows = rows
      let ecData = {}
      if (this.mortal.type == 20) {
        ecData = { sources_link: this.selectedEC }
        if (
          this.dummyDataColumns &&
          this.dummyDataColumns.length == 1 &&
          !this.dummyDataColumns[0].header
        ) {
          this.mapEcColumnsToDummy()
        }
      }

      // await this.$store.commit('ReportBuilder/setDataSourceOfAElement', {
      //   dataSource: {
      //     ...this.selectedElement.dataSource,
      //     ...ecData,
      //     headers: this.dummyDataColumns,
      //     rows: this.dummyDataRows,
      //     creatorComment: this.creatorComment
      //   }
      // })
      // if (this.selectedElement.type == 'graph') {
      //   this.mapHeaderWithConfiguration()
      // }
      await this.setSelectedElementsProps({
        prop: 'dataSource',
        value: {
          ...this.selectedElement.dataSource,
          ...ecData,
          headers: this.dummyDataColumns,
          rows: this.dummyDataRows,
          creatorComment: this.creatorComment
        }
      })

      await this.$store.commit('ReportBuilder/setLocalVersionOfElementsData', {
        prop: 'dataSource',
        data: {
          ...this.selectedElement.dataSource,
          ...ecData,
          headers: this.dummyDataColumns,
          rows: this.dummyDataRows,
          creatorComment: this.creatorComment
        },
        _id: this.selectedElement._id
      })
      console.log(this.selectedElement, '--------------')
      this.$parent.setPanelSettingOptions()
    },
    setInitialData() {
      console.log('this.selectedElement :>> ', this.selectedElement)
      if (
        this.selectedElement &&
        this.selectedElement.dataSource &&
        this.selectedElement.dataSource.headers
      ) {
        this.dummyDataColumns = this.selectedElement.dataSource.headers
        this.dummyDataRows = this.selectedElement.dataSource.rows || []
        this.isSetDataSource = false
      } else {
        this.dummyDataColumns = [this.getDummyDataColumn()]
        this.dummyDataRows = []
        this.isSetDataSource = false
      }
      if (this.selectedElement.type == 'graph') {
        this.oldDataHeader = JSON.parse(JSON.stringify(this.dummyDataColumns))
      }

      this.creatorComment =
        (this.selectedElement.dataSource &&
          this.selectedElement.dataSource.creatorComment) ||
        ''

      this.selectedEC =
        (this.selectedElement.dataSource &&
          this.selectedElement.dataSource.sources_link) ||
        null

      if (this.selectedEC) {
        this.getExportData({
          name: this.selectedEC,
          params: {}
        })
      }
      this.hidden = this.mortal.type == 20 || this.mortal.type > 20
      this.showEcPanelToOtherThen20 = this.mortal.type > 20 && !!this.selectedEC
    },
    togglePanel() {
      this.hidden = !this.hidden
    },
    async getSelectedEcData() {
      this.$store.commit('loader', true, { root: true })
      this.exportparams = {}
      await this.getExportData({
        name: this.selectedEC,
        params: this.exportparams
      })
      this.setDataSource()
      this.isDummyDataValid()
      this.$store.commit('loader', false, { root: true })
    },
    changeECParam(event, key) {
      console.log(typeof event)
      this.ecParams[key] = event
      console.log(this.ecParams)
    },
    async getSelectedEc() {
      // this.isDummyDataValid()
      //   console.log(this.ecParams)
      this.exportparams = {}
      Object.assign(this.exportparams, this.ecParams)
      //  console.log(abc)
      //  console.log(this.exportparams)
      await this.getExportData({
        name: this.selectedEC,
        params: this.exportparams
      })
    }
  },
  async created() {
    console.log('<===DATA_SOURCES===>')
    this.setInitialData()
    this.requestorType = this.mortal.type
  },
  watch: {
    dummyDataColumns: {
      handler: 'setDataSource',
      deep: true
    },
    selectedElement() {
      console.log('<===DATA_SOURCES-c===>')
      this.setInitialData()
    }
  }
}
</script>


<style scoped>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  /* margin-bottom: 8px; */
}
.v-text-field{
  width: 175px;
  margin-top: 25px;
  margin-left: 8px;
}
</style>

<style>
.v-text-field{
  width: 400px;
}
thead.v-data-table-header {
  background-color: #6495ed94;
  color: white !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 10px 10px;
  flex: 1 1 auto;
  max-width: 100%;
}
.v-expansion-panel-header {
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 35px;
  outline: none;
  padding: 7px 11px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

v-expansion-panel::before {
  border-radius: inherit;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  /* box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
}
.v-expansion-panel::before {
  border-radius: inherit;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  box-shadow: unset;
}
/* .v-label {
  font-size: small;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
} */
.v-input--switch__track {
  border-radius: 8px;
  width: 30px;
  height: 8px;
  left: 2px;
  position: absolute;
  opacity: 0.6;
  right: 2px;
  top: calc(50% - 7px);
}
.v-input--switch__thumb {
  border-radius: 50%;
  top: calc(50% - 10px);
  height: 15px;
  position: relative;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
/* .v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  align-items: stretch;
  min-height: 20px;
} */
.v-text-field {
  font-size: small;
}
/* .v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: unset;
} */
.table-header {
  font-size: 14px;
  color: grey;
}
p {
  font-size: 16px;
  margin: 0 !important;
}
/* .toolbar-error-title {
  background-image: linear-gradient(70deg, #CB356B, #BD3F32);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
} */
</style>

